

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;









const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 14px;
          text-align:center;
      }

`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function TatFaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5">
            <Row className="justify-content-center">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about Tattoo Removal</Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col

              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How many laser sessions are usually required for tattoo removal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Typically, it takes an average of 6-12 sessions to completely remove a tattoo, with
                        variations—some requiring fewer sessions and others, more. The number of sessions is influenced
                        by factors like the tattoo's location, ink type, skin type, aftercare, and the individual's
                        immune system health.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is your treatment suitable for all skin types?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>

                        Certainly, at Dermamina, we can safely address black ink tattoos across all Fitzpatrick skin
                        types.<br /> <br />

                        However, it's important to note that we can only safely address colored ink tattoos on
                        Fitzpatrick skin types 1-3. This precaution is due to the potential risk of laser-induced damage
                        to the melanocytes in the skin for Fitzpatrick skin types 4-6. <br /> <br />

                        For a personalized assessment of the potential outcomes for your specific tattoo, feel free to
                        reach out to one of our knowledgeable consultants.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What is the price for tattoo removal in London?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>

                        The cost of tattoo removal is determined by the size of your tattoo and your choice between 'pay
                        as you go' or selecting one of our cost-effective packages, which are detailed on our pricing
                        page.<br /> <br />

                        While a consultation and patch test are available for £50, they are optional for new
                        clients.<br /> <br />

                        During your 30-45 minute sessions with our consultants, it's often feasible to address more than
                        one tattoo, and you can return for treatment every 2-3 weeks. On average, complete tattoo
                        removal requires 6-12 sessions.<br /> <br />

                        When you visit our London studio, you'll benefit from detailed dermatoscope imaging of your
                        tattoo(s) to monitor progress. Additionally, you'll receive a personalized treatment plan
                        tailored to your tattoo and lifestyle.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


              </Col>


            </Row>


            <br />

            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>


    );
}



